<template>
  <div
    class="datepicker"
    :class="{
      'datepicker--error': error,
      'datepicker--focused': focused
    }"
  >
    <label v-if="label" class="datepicker__label" :for="name ? name : uuid" :data-label-lg="labelLg || label">
      <span>{{ label }}</span>
    </label>

    <Datepicker
      :id="name ? name : uuid"
      :name="name"
      ref="datepicker"
      class="datepicker__input"
      @focus="focused = true"
      @blur="focused = false"
      :placeholder="placeholder"
      v-model="date"
      inputFormat="MM/dd/yyyy"
      :disabled="disabled"
      :lowerLimit="lowerLimit"
      :upperLimit="upperLimit"
    />

    <p v-if="info" class="datepicker__info">{{ info }}</p>
  </div>
</template>

<script>
import { nextTick } from 'vue'
import Datepicker from 'vue3-datepicker'

export default {
  props: {
    label: String,
    labelLg: String,
    placeholder: String,
    value: String,
    error: Boolean,
    info: String,
    name: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    lowerLimit: Date,
    upperLimit: Date
  },
  components: {
    Datepicker
  },
  data() {
    return {
      date: null,
      focused: false
    }
  },
  mounted() {
    this.setInitial()
  },
  methods: {
    setInitial() {
      this.date = this.value ? new Date(this.value) : null
    },
    setFocus() {
      nextTick(() => {
        if (!this.disabled) {
          this.$refs.datepicker.inputRef.focus()
        }
      })
    }
  },
  watch: {
    date() {
      const dateString = this.date.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      })

      this.$emit('update:value', dateString)
    },
    value() {
      this.setInitial()
    }
  }
}
</script>

<style lang="scss">
.datepicker {
  $this: &;

  position: relative;
  border: 1px solid #f3f3f3;
  border-radius: 4px;

  &__label {
    position: absolute;
    top: 0;
    left: 16px;
    padding: 0 4px;
    transform: translateY(-50%);
    font-weight: 600;
    font-size: 16px;
    color: #4e5d78;
    z-index: 1;
    line-height: 18px;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 4px;
      background-color: #fff;
    }

    > span {
      display: none;

      @include media-query(lg) {
        display: inline;
      }
    }

    &::after {
      content: attr(data-label-lg);

      @include media-query(lg) {
        display: none;
      }
    }
  }

  &__input {
    font-family: 'Inter', sans-serif;
    display: block;
    width: 100%;
    line-height: 58px;
    height: 58px;
    padding-left: 20px;
    padding-right: 20px;
    border: 0;
    border-radius: 4px;

    font-weight: 500;
    font-size: 16px;
    color: #323b4b;

    &:focus {
      outline: 0;
    }

    &::placeholder {
      font-weight: 500;
      font-size: 16px;
      color: #b0b7c3;
      font-family: inherit;
    }

    &:disabled {
      background-color: get-color(bg-3);
    }
  }

  &__info {
    position: absolute;
    left: 0;
    top: 100%;
    font-size: 12px;
    line-height: 16px;
    padding-top: 3px;
    color: get-color(text-3);
  }

  // datepicker styling
  --vdp-elem-border-radius: 0;
  --vdp-hover-color: #000;
  --vdp-hover-bg-color: #f3f3f3;
  --vdp-selected-bg-color: #22c58b;

  button {
    font-family: inherit;
  }

  // issues with :focus-within pseudoclass
  &--focused {
    border-color: rgba(get-color(primary-1), 0.4);
    box-shadow: 0 0 10px rgba(get-color(primary-1), 0.2);
  }

  &--error {
    border-color: get-color(primary-4);

    #{$this}__info {
      color: get-color(primary-4);
    }
  }
}
</style>
