<template>
  <div class="input-address">
    <div class="input-address__input">
      <Input
        ref="input"
        :label="label"
        :placeholder="placeholder"
        v-model:value="location"
        :suggestion="currentPrediction"
        :dropdown="dropdownVisible"
        :error="error"
        :info="info"
        :name="name"
        :mask="mask"
        @keydown.down.prevent="nextPrediction"
        @keydown.up.prevent="prevPrediction"
        @keyup="onInputKey($event)"
        @keyup.enter="setLocation()"
        @blur="setLocation()"
      />
    </div>

    <ul class="input-address__predictions" v-if="dropdownVisible" ref="dropdown">
      <li v-for="(prediction, index) in predictions" :key="prediction">
        <a
          href="#"
          class="input-address__prediction"
          :class="{
            'input-address__prediction--active': focusedPredictionIndex === index
          }"
          tabindex="-1"
          @click.prevent="setLocation(prediction)"
          @mouseover="focusedPredictionIndex = index"
          >{{ prediction.description }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

import { Loader } from '@googlemaps/js-api-loader'

import { API_KEY } from '../../data/google-maps'

import Input from './Input.vue'

export default {
  name: 'InputAddress',
  props: {
    error: Boolean,
    label: String,
    placeholder: String,
    value: String,
    info: String,
    mask: {
      type: [Object, String]
    },
    name: {
      type: String,
      default: ''
    }
  },
  components: {
    Input
  },
  data() {
    return {
      google: null,
      autocompleteService: null,
      placesService: null,
      predictions: [],
      focusedPredictionIndex: -1,
      currentPrediction: '',
      location: '',
      locationId: '',
      dropdownVisible: false,
      loading: false
    }
  },
  async mounted() {
    this.location = this.value

    const loader = new Loader({
      apiKey: API_KEY,
      libraries: ['places']
    })

    this.google = await loader.load()

    this.autocompleteService = new this.google.maps.places.AutocompleteService()
    this.geocoder = new this.google.maps.Geocoder()
  },
  methods: {
    setFocus() {
      this.$refs.input.setFocus()
    },
    onInputKey(event) {
      if (event.key !== 'ArrowDown' && event.key !== 'ArrowUp' && event.key !== 'Enter') {
        this.findPredictions()
      }
    },
    setPredictions(predictions, status) {
      if (status !== this.google.maps.places.PlacesServiceStatus.OK) {
        this.predictions = []
        return
      }

      this.predictions = predictions

      if (this.focusedPredictionIndex > -1) {
        this.currentPrediction = this.predictions[this.focusedPredictionIndex].description
      }
    },
    setDropdownFocus() {
      this.focusedPredictionIndex = 0
    },
    setLocation() {
      if (this.focusedPredictionIndex < 0) {
        return
      }

      const location = this.predictions[this.focusedPredictionIndex]

      this.dropdownVisible = false
      this.location = location.description
      this.locationId = location.place_id

      this.changeLocation(location.place_id)
    },
    nextPrediction() {
      if (this.focusedPredictionIndex + 1 < this.predictions.length) {
        this.focusedPredictionIndex = this.focusedPredictionIndex + 1
      }
    },
    prevPrediction() {
      if (this.focusedPredictionIndex > -1) {
        this.focusedPredictionIndex = this.focusedPredictionIndex - 1
      }
    },
    async changeLocation(locationId) {
      const { results } = await this.geocoder.geocode({
        placeId: locationId
      })
      this.dropdownVisible = false
      const locationDetails = results[0].address_components.reduce(
        (acc, cur) => ({
          ...acc,
          ...cur.types.reduce(
            (accType, type) => ({
              ...accType,
              [type]: type === 'administrative_area_level_1' ? cur.short_name : cur.long_name
            }),
            {}
          )
        }),
        {}
      )
      const address = {
        streetAddress: `${locationDetails.street_number || ''} ${locationDetails.route || ''}`,
        city: `${locationDetails.locality || locationDetails.sublocality || ''}`,
        zipCode: locationDetails.postal_code,
        state: locationDetails.administrative_area_level_1
      }
      this.$emit('changeLocation', address)
      this.location = address.streetAddress
      this.currentPrediction = ''
    },
    // https://developers.google.com/maps/documentation/places/web-service/supported_types#table3
    findPredictions: debounce(function () {
      if (this.location && this.autocompleteService) {
        this.autocompleteService.getPlacePredictions(
          {
            input: this.location,
            types: ['address'],
            componentRestrictions: {
              country: 'us'
            }
          },
          this.setPredictions
        )

        this.dropdownVisible = true
      } else {
        this.focusedPredictionIndex = -1
        this.dropdownVisible = false
      }
    }, 400)
  },
  watch: {
    focusedPredictionIndex(newValue) {
      if (newValue > -1) {
        this.currentPrediction = this.predictions[newValue].description
      } else {
        this.currentPrediction = ''
      }
    },
    currentPrediction(newValue) {
      if (newValue !== '') {
        const length = this.location.length

        this.location = newValue.substr(0, length)
      }
    }
  }
}
</script>

<style lang="scss">
.input-address {
  position: relative;
  z-index: 2;

  &__predictions {
    position: absolute;
    left: 0;
    width: 100%;
    top: 100%;
    margin: 0;
    padding: 0;
    border: 1px solid #000;
    background-color: #fff;
    border: 1px solid #f3f3f3;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 0;
    list-style: none;
    max-height: 220px;
    overflow: auto;
  }

  &__prediction {
    display: block;
    padding: 8px;
    padding-left: 20px;

    &--active {
      outline: 0;
      background-color: get-color(primary-1);
      color: #fff;
    }
  }
}
</style>
