<template>
  <div class="plaid">
    <Toast class="plaid__error" v-if="error" error>
      {{ error }}
    </Toast>

    <Toast v-if="!error && !!value && !!value.bankSource && !!value.accountId" success>
      <div class="plaid__success">
        <div class="plaid__success-content">{{ success }}</div>

        <div class="plaid__success-cta">
          <Button @click="openPlaid()">Edit</Button>
        </div>
      </div>
    </Toast>

    <div v-else>
      <Grid tightVertically class="plaid__main">
        <GridItem :cols="12" :md="6">
          <Button large expanded @click="openPlaid()">
            {{ label }}
          </Button>
        </GridItem>

        <GridItem :cols="12">
          <P small2>{{ info }}</P>
        </GridItem>
      </Grid>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref } from 'vue'

import Button from './Button.vue'
import Grid from './Grid.vue'
import GridItem from './GridItem.vue'
import P from './P.vue'
import Toast from './Toast.vue'

export default {
  props: {
    label: String,
    info: {
      type: String,
      default: ''
    },
    success: String,
    value: Object,
    clientName: {
      type: String,
      default: 'Test App'
    }
  },
  components: {
    Button,
    Grid,
    GridItem,
    P,
    Toast
  },
  setup() {
    return {
      openPlaid: null,
      destroyPlaid: null,
      store: useStore(),
      error: ref('')
    }
  },
  methods: {
    test(metadata) {
      const { institution } = metadata

      if (['Chime', 'Varo Bank'].includes(institution.name)) {
        return [
          true,
          'We are sorry, we do not currently accept non-traditional bank accounts such as Chime, Simple, or Varo. Please use a traditional bank account to continue.'
        ]
      }

      return [false, '']
    },
    onSuccess(token, metadata) {
      const [err, msg] = this.test(metadata)

      this.error = msg

      if (err) {
        return
      }

      const account = metadata.accounts.find((account) => account.subtype === 'checking')

      if (account) {
        this.$emit('update:value', {
          bankSource: token,
          accountId: account.id
        })
      } else {
        console.log('Error')
      }
    }
  },
  mounted() {
    const { open, destroy } = window.Plaid.create({
      clientName: this.clientName,
      env: process.env.VUE_APP_PLAID_ENV || 'sandbox',
      key: process.env.VUE_APP_PLAID_KEY,
      product: 'auth',
      apiVersion: 'v2',
      selectAccount: true,
      onSuccess: this.onSuccess
    })

    this.openPlaid = open
    this.destroyPlaid = destroy
  },
  beforeUnmount() {
    this.destroyPlaid()
  }
}
</script>

<style lang="scss" scoped>
.plaid {
  &__error {
    margin-bottom: 24px;
  }

  &__main {
    align-items: center;
    text-align: center;

    @include media-query(md) {
      text-align: left;
    }
  }

  &__success {
    display: flex;
    flex-direction: row;
    align-items: center;

    &-content {
      flex: 1 1 auto;
    }

    &-cta {
      margin-left: 8px;
      flex: 0 0 auto;
    }
  }
}
</style>
