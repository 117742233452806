<template>
  <div class="form">
    <div class="form__section" v-for="{ heading, grid, inputs } in sections" :key="getLocal(language, heading.label)">
      <div class="form__header">
        <div class="form__heading-container">
          <H v-bind="{ [heading.type]: true }">{{ getLocal(language, heading.label) }}</H>
        </div>

        <div class="form__additional" v-if="heading.editTo">
          <Button dimmed :to="heading.editTo">{{ language === 'Spanish' ? 'Editar' : 'Edit' }}</Button>
        </div>
      </div>

      <div class="form__group" v-if="inputs.length > 0">
        <Grid v-bind="{ [grid]: true }">
          <GridItem
            v-for="{ gridItem, input } in inputs"
            :key="input.name"
            :cols="gridItem.cols"
            :sm="gridItem.sm"
            :md="gridItem.md"
          >
            <InputPreview
              v-if="input.type === 'preview'"
              :ref="(el) => setInputRef(el, input.name)"
              :label="getLocal(language, input.label)"
              :value="
                typeof formData[input.name] === 'object'
                  ? formData[input.name].label || `-`
                  : formData[input.name] || `-`
              "
              :nowrap="input.nowrap"
            />

            <Datepicker
              v-else-if="input.type === 'date'"
              :ref="(el) => setInputRef(el, input.name)"
              :label="getLocal(language, input.label)"
              :placeholder="getLocal(language, input.placeholder) || input.placeholder"
              :error="errors[input.name][0]"
              :info="getLocal(language, errors[input.name][1])"
              :name="input.name"
              v-model:value="formData[input.name]"
              :disabled="typeof input.disabled === 'function' ? input.disabled(formData) : false"
              :lowerLimit="typeof input.lowerLimit === 'function' ? input.lowerLimit(formData) : undefined"
              :upperLimit="typeof input.upperLimit === 'function' ? input.upperLimit(formData) : undefined"
              @update:value="
                () => {
                  focusNext(input.name, input.test, input.next)
                  clearError(input.name)
                }
              "
            />

            <Radio
              v-else-if="input.type === 'radio'"
              :ref="(el) => setInputRef(el, input.name)"
              :label="getLocal(language, input.label)"
              :value="input"
              :error="errors[input.name][0]"
              v-model:modelValue="formData[input.name]"
              @update:modelValue="clearError(input.name)"
            />

            <Select
              v-else-if="input.type === 'select'"
              :ref="(el) => setInputRef(el, input.name)"
              :label="getLocal(language, input.label)"
              :placeholder="getLocal(language, input.placeholder) || input.placeholder"
              :options="getLocal(language, input.options) || input.options"
              :error="errors[input.name][0]"
              :disabled="typeof input.disabled === 'function' ? input.disabled(formData) : false"
              :info="getLocal(language, errors[input.name][1]) || errors[input.name][1]"
              :name="input.name"
              v-model:value="formData[input.name]"
              @update:value="
                () => {
                  focusNext(input.name, input.test, input.next)
                  clearError(input.name)
                }
              "
            />

            <InputAddress
              v-else-if="input.type === 'address'"
              :ref="(el) => setInputRef(el, input.name)"
              :label="getLocal(language, input.label)"
              :placeholder="getLocal(language, input.placeholder) || input.placeholder"
              :options="input.options"
              :mask="input.mask"
              :error="errors[input.name][0]"
              :info="getLocal(language, errors[input.name][1])"
              @changeLocation="
                (obj) => {
                  focusNext(input.name, input.test, input.next, true)
                  setAddress(obj)
                }
              "
              :name="input.name"
              v-model:value="formData[input.name]"
              @update:value="clearError(input.name)"
            />

            <Plaid
              v-else-if="input.type === 'plaid'"
              :ref="(el) => setInputRef(el, input.name)"
              :label="getLocal(language, input.label)"
              :info="getLocal(language, input.info)"
              :success="getLocal(language, input.success)"
              client-name="Kafene"
              v-model:value="formData[input.name]"
              @update:value="clear(input.clear)"
            />

            <Input
              v-else
              :label="getLocal(language, input.label)"
              :ref="(el) => setInputRef(el, input.name)"
              :labelLg="input.labelLg"
              :placeholder="getLocal(language, input.placeholder) || input.placeholder"
              :mask="input.mask"
              :error="errors[input.name][0]"
              :info="getLocal(language, errors[input.name][1])"
              :disabled="disabled[input.name]"
              :name="input.name"
              :type="input.type"
              :msg="getLocal(language, input.msg)"
              :inputmode="input.inputmode"
              :data-element="input['data-element']"
              v-model:value="formData[input.name]"
              @update:value="
                () => {
                  focusNext(input.name, input.test, input.next)
                  clearError(input.name)
                }
              "
            />

            <div v-if="input.name === 'monthlyIncome' && monthlyIncome > 0" class="annual__income">
              <span v-if="language === 'Spanish'">Ganas {{ yearlyIncome }} al año.</span>
              <span v-else>You make {{ yearlyIncome }} a year.</span>
            </div>
          </GridItem>
        </Grid>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { getLocal } from '../data/locales'

import { views, inputs, sections, validators, errors } from '../data/application-form'

import Button from '../components/common/Button.vue'
import Input from '../components/common/Input.vue'
import InputAddress from '../components/common/InputAddress.vue'
import InputPreview from '../components/common/InputPreview.vue'
import Select from '../components/common/Select.vue'
import H from '../components/common/H.vue'
import Datepicker from '../components/common/Datepicker.vue'
import Plaid from '../components/common/Plaid.vue'
import Radio from '../components/common/Radio.vue'
import Grid from '../components/common/Grid.vue'
import GridItem from '../components/common/GridItem.vue'
import { moneyMask } from '../helpers/general'

export default {
  name: 'Form',
  props: {
    step: String,
    nextClickCounter: Number
  },
  components: {
    Button,
    Input,
    InputAddress,
    InputPreview,
    Plaid,
    Radio,
    Select,
    H,
    Grid,
    GridItem,
    Datepicker
  },
  setup(props) {
    const store = useStore()

    const currentView = views.find(({ no }) => no === props.step)

    const formInputs = inputs(sections([currentView]))

    const initials = store.getters[`application/getFields`](formInputs.map((input) => input.name))

    const externalFieldsInitials = formInputs
      .filter((i) => i.getter !== undefined)
      .reduce(
        (acc, cur) => ({
          ...acc,
          [cur.name]: store.getters[cur.getter]
        }),
        {}
      )

    return {
      key: ref(0),
      inputs: formInputs,
      sections: sections([currentView]),
      inputRefs: ref({}),
      formData: ref({ ...initials, ...externalFieldsInitials }),
      validators: validators(formInputs),
      errors: ref(errors(formInputs)),
      review: formInputs.every((input) => input.type === 'preview')
    }
  },
  methods: {
    getLocal,
    setInputRef(el, name) {
      this.inputRefs[name] = el
    },
    focusNext(name, test, next, force) {
      if (!next) {
        return
      }

      const value = this.formData[name]
      const nextInput = this.inputRefs[next]

      const conditions = [
        !!value,
        typeof test === 'function',
        nextInput !== undefined,
        !test(value, this.formData)[0],
        nextInput.setFocus && typeof nextInput.setFocus === 'function'
      ]

      if (conditions.every((el) => el === true) || force) {
        nextInput.setFocus()
      }
    },
    clear(fields) {
      fields.map((f) => {
        this.clearError(f)
        this.formData[f] = ''
      })
    },
    async valid() {
      for (const [key, value] of Object.entries(this.formData)) {
        if (this.validators[key]) {
          const input = this.inputs.find((i) => i.name === key)

          this.errors[key] =
            typeof input.disabled === 'function' && input.disabled(this.formData)
              ? [false, '']
              : await this.validators[key](value, this.formData)
        }
      }

      return Object.values(this.errors).every(([error]) => error === false)
    },
    setAddress({ streetAddress, city, zipCode, state }) {
      const formDatawithAddressDetails = {
        ...this.formData,
        streetAddress,
        city,
        zipCode,
        state: this.inputs.find(({ name }) => name === 'state').options.find((s) => s.value === state)
      }

      this.formData = formDatawithAddressDetails
    },
    writeToStore() {
      this.$store.dispatch('application/setFields', {
        formData: this.formData
      })
    },
    clearError(field) {
      this.errors[field] = [false, '']
    }
  },
  watch: {
    async nextClickCounter() {
      const valid = await this.valid()

      if (!this.review && valid) {
        this.writeToStore()
      }

      if (valid) {
        this.$emit('ready', { ready: true })
      } else {
        this.$emit('ready', { ready: false })
      }
    }
  },
  computed: {
    disabled() {
      return this.inputs.reduce(
        (acc, i) => ({
          ...acc,
          [i.name]: typeof i.disabled === 'function' && i.disabled(this.formData)
        }),
        {}
      )
    },
    language() {
      return this.$store.getters.getLanguage
    },
    monthlyIncome() {
      if (this.formData.monthlyIncome) {
        return parseInt(this.formData.monthlyIncome.replace('$', '').replace(',', ''))
      }
      return 0
    },
    yearlyIncome() {
      return moneyMask((this.monthlyIncome * 12).toFixed(2))
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  > * + * {
    margin-top: 32px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__heading-container {
    flex: 1 1 auto;
  }

  &__additional {
    flex: 0 0 auto;
  }

  &__group {
    margin-top: 32px;

    &--tight {
      margin-top: 16px;
    }
  }

  .annual__income {
    display: flex;
    font-size: 12px;
    line-height: 16px;
    margin-top: 8px;
    color: get-color(text-3);
    margin-bottom: 1em;
  }
}
</style>
