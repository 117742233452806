<template>
  <div class="radio">
    <div class="radio__control">
      <input
        class="radio__input"
        type="radio"
        :id="uuid"
        :value="value"
        :checked="isChecked"
        @input.stop="$emit('update:modelValue', value)"
      />

      <label class="radio__label" :for="uuid"></label>
    </div>

    <p class="radio__description">{{ label }}</p>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    placeholder: String,
    value: Object,
    modelValue: Object
  },
  computed: {
    isChecked() {
      return this.modelValue.value == this.value.value
    }
  }
}
</script>

<style lang="scss" scoped>
.radio {
  $this: &;

  display: flex;
  flex-direction: row;

  &__control {
    flex: 0 0 auto;
    position: relative;
    width: 20px;
    height: 20px;
  }

  &__input {
    visibility: hidden;
    opacity: 0;

    &:checked {
      + #{$this}__label {
        &::after {
          visibility: visible;
          opacity: 1;
          transform: translate(-50%, -50%) scale(4);
        }
      }
    }
  }

  &__label {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fafbfc;
    border: 1px solid #4e5d78;
    border-radius: 50%;
    line-height: 18px;

    &::after {
      content: '';
      visibility: hidden;
      opacity: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 2px;
      height: 2px;
      transform: translate(-50%, -50%) scale(1);
      border-radius: inherit;
      background-color: #4e5d78;
      transform-origin: center center;
      transition: all 100ms ease;
    }
  }

  &__description {
    flex: 1 1 auto;
    margin-left: 12px;
    text-align: left;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #4e5d78;
  }
}
</style>
