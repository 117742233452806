<template>
  <div
    class="grid"
    :class="{
      'grid--tight': tight,
      'grid--tight-vertically': tightVertically
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Grid',
  props: {
    tight: Boolean,
    tightVertically: Boolean
  }
}
</script>

<style lang="scss">
.grid {
  $this: &;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -12px;

  @include media-query(lg) {
    margin: -16px -24px;
  }

  &--tight {
    margin: -12px -6px;

    @include media-query(lg) {
      margin: -16px -12px;
    }

    #{$this}-item {
      padding: 12px 6px;

      @include media-query(lg) {
        padding: 16px 12px;
      }
    }
  }

  &--tight-vertically {
    margin: -8px -16px;

    @include media-query(lg) {
      margin: -8px -24px;
    }

    #{$this}-item {
      padding: 8px 16px;

      @include media-query(lg) {
        padding: 8px 24px;
      }
    }
  }
}
</style>
