<template>
  <div
    class="grid-item"
    :class="{
      [`grid-item--${cols}`]: !!cols,
      [`grid-item--${sm}@sm`]: !!sm,
      [`grid-item--${md}@md`]: !!md,
      [`grid-item--${lg}@md`]: !!lg,
      'grid-item--auto': auto
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'GridItem',
  props: {
    cols: [String, Number],
    sm: [String, Number],
    md: [String, Number],
    lg: [String, Number],
    auto: Boolean
  }
}
</script>

<style lang="scss">
.grid-item {
  flex: 1;
  max-width: 100%;
  padding: 16px 12px;

  @include media-query(lg) {
    padding: 16px 24px;
  }

  @for $i from 1 through 12 {
    &--#{$i} {
      flex-basis: percentage($i / 12);
      max-width: percentage($i / 12);

      @if $i == 12 {
        width: 100%;
      }
    }
  }

  @each $breakpoint, $width in $breakpoints {
    @include media-query($breakpoint) {
      @for $i from 1 through 12 {
        &--#{$i}\@#{$breakpoint} {
          flex-basis: percentage($i / 12);
          max-width: percentage($i / 12);
        }
      }
    }
  }

  &--auto {
    flex: 0 0 auto;
  }
}
</style>
