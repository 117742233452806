<template>
  <Main>
    <div class="details-form">
      <div class="details-form__stepline">
        <Stepline :active="currentStep" :steps="steps" />
      </div>

      <div v-if="error" class="details-form__error">
        <Toast error>{{ error }}</Toast>
      </div>

      <div class="details-form__content">
        <Form :step="currentStep" :nextClickCounter="nextClickCounter" @ready="tryNextRoute" />
      </div>
    </div>

    <template v-slot:footer>
      <div class="details-form__footer">
        <div class="details-form__footer-item">
          <Button v-if="prevRoute" :to="prevRoute" :disabled="isLoading" link icon>
            <template v-slot:icon>
              <svg width="7" height="11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6 1L1.75 5.25 6 9.5"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </template>

            <span>{{ language === 'Spanish' ? 'Espalda' : 'Back' }}</span>
          </Button>
        </div>

        <div class="details-form__footer-item-end">
          <Button v-if="nextRoute" :loading="isLoading" :disabled="isLoading" @click="onNextClick">{{
            language === 'Spanish' ? 'Seguir' : 'Continue'
          }}</Button>
        </div>
      </div>
    </template>
  </Main>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { views } from '../data/application-form'
import { getLocal } from '../data/locales'

import Button from '../components/common/Button.vue'
import Form from '../components/Form.vue'
import Main from '../components/Main.vue'
import Stepline from '../components/Stepline.vue'
import Toast from '../components/common/Toast.vue'

export default {
  name: 'DetailsForm',
  components: {
    Button,
    Form,
    Main,
    Stepline,
    Toast
  },
  setup() {
    const store = useStore()

    return {
      store,
      error: ref(''),
      isLoading: ref(false),
      nextClickCounter: ref(0)
    }
  },
  methods: {
    tryNextRoute({ ready }) {
      this.isLoading = false

      if (ready) {
        this.$router.push({ path: this.nextRoute })
      }
    },
    onNextClick() {
      this.isLoading = true
      this.nextClickCounter = ++this.nextClickCounter
    }
  },
  computed: {
    language() {
      return this.$store.getters.getLanguage
    },
    steps() {
      return views.map(({ no, name }) => ({
        no,
        label: getLocal(this.language, name)
      }))
    },
    currentStep() {
      return this.$route.meta.step
    },
    prevRoute() {
      return this.$route.meta.prev
    },
    nextRoute() {
      return this.$route.meta.next
    }
  }
}
</script>

<style lang="scss" scoped>
.details-form {
  &__stepline {
    padding-bottom: 24px;
  }

  &__error {
    margin-bottom: 24px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__footer-item {
    flex: 1 1 auto;
  }

  &__footer-item-end {
    flex: 0 0 auto;
    margin-left: 24px;
  }
}
</style>
