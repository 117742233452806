<template>
  <div class="input-preview">
    <p class="input-preview__label">{{ label }}</p>

    <p class="input-preview__text" :class="{ 'input-preview__text--nowrap': nowrap }">{{ value }}</p>
  </div>
</template>

<script>
export default {
  name: 'InputPreview',
  props: {
    label: String,
    value: String,
    nowrap: Boolean
  }
}
</script>

<style lang="scss" scoped>
.input-preview {
  &__label {
    font-weight: 600;
    font-size: 14px;
    line-height: 29px;
    color: #b0b7c3;
    white-space: nowrap;
    line-height: 18px;
  }

  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 29px;
    color: #323b4b;
    overflow-wrap: break-word;

    &--nowrap {
      white-space: nowrap;
      overflow-wrap: normal;
    }
  }
}
</style>
