<template>
  <ul class="stepline" :class="`stepline--${active}`">
    <li
      v-for="(step, index) in steps"
      :key="step.label"
      class="stepline__item"
      :class="{
        'stepline__item--active': active === step.no,
        'stepline__item--done': parseInt(active) > index + 1
      }"
    >
      <div class="stepline__item-no">
        <span>{{ step.no }}</span>
      </div>

      <p class="stepline__item-info">{{ step.label }}</p>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Stepline',
  props: {
    active: String,
    steps: Array
  }
}
</script>

<style lang="scss" scoped>
.stepline {
  $this: &;
  $line-top: 12px;
  $line-width: 2px;

  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: $line-top;
    height: $line-width;
    background-color: #b0b7c3;
  }

  &__item {
    position: relative;
    z-index: 1;
    color: #b0b7c3;

    &:first-child,
    &:last-child {
      #{$this}__item-no {
        &::before {
          content: '';
          position: absolute;
          right: 100%;
          width: 200px;
          height: 100%;
          background-color: #fff;
          z-index: -1;
        }
      }
    }

    &:first-child {
      #{$this}__item-no {
        &::before {
          right: 100%;
        }
      }
    }

    &:last-child {
      #{$this}__item-no {
        &::before {
          left: 100%;
        }
      }
    }

    &-no {
      position: relative;
      margin: 0 auto;
      width: 26px;
      height: 26px;
      line-height: 22px;
      text-align: center;
      font-weight: 600;
      font-size: 12px;
      color: inherit;
      border-radius: 50%;
      border: $line-width solid #b0b7c3;
      background-color: #fff;
    }

    &-info {
      margin-top: 12px;
      font-weight: 600;
      font-size: 14px;
      line-height: 29px;
      color: inherit;

      @include media-query(sm) {
        margin-top: 8px;
        font-size: 18px;
      }
    }

    &--active {
      color: #22c58b;
      z-index: 0;

      &::before {
        content: '';
        position: absolute;
        right: 50%;
        top: $line-top;
        width: 100vw;
        height: $line-width;
        background-color: #22c58b;
        z-index: -1;
      }

      #{$this}__item-no {
        border-color: #22c58b;
      }
    }

    &--done {
      color: #22c58b;

      #{$this}__item-no {
        border-color: #22c58b;
        background-color: #22c58b;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.338.301a.969.969 0 011.357-.046.939.939 0 01.047 1.339l-6.72 7.105a.97.97 0 01-1.327.073L.335 5.93a.939.939 0 01-.104-1.336.969.969 0 011.354-.103l2.661 2.251L10.338.301z' fill='%23fff'/%3E%3C/svg%3E");

        > span {
          display: none;
        }
      }
    }
  }
}
</style>
