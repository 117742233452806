<template>
  <div
    class="select"
    :class="{
      'select--borderless': borderless,
      'select--error': error,
      'select--info-spacing': info
    }"
  >
    <label v-if="label" :for="name ? name : uuid" class="select__label">{{ label }}</label>

    <select
      :name="name"
      :id="name ? name : uuid"
      class="select__input"
      :disabled="disabled"
      :class="{ 'select__input--initial': isPlaceholderSelected && placeholder }"
      :value="value.value"
      ref="select"
      @change.stop="
        $emit(
          'update:value',
          options.find((item) => item.value === $event.target.value)
        )
      "
    >
      <option v-if="placeholder" value="" disabled hidden class="select__option">{{ placeholder }}</option>

      <option v-for="option in options" :value="option.value" :key="option.label" class="select__option">
        {{ option.label }}
      </option>
    </select>

    <p v-if="info" class="select__info">{{ info }}</p>
  </div>
</template>

<script>
export default {
  props: {
    borderless: Boolean,
    id: String,
    label: String,
    placeholder: String,
    options: Array,
    value: Object,
    error: Boolean,
    info: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isPlaceholderSelected() {
      if (this.value.value) {
        return false
      }
      return true
    }
  },
  methods: {
    setFocus() {
      this.$refs.select.focus()
    }
  },
  watch: {
    disabled(newVal) {
      if (newVal) {
        this.$emit('update:value', {})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.select {
  $this: &;

  position: relative;
  border: 1px solid #f3f3f3;
  border-radius: 4px;

  &:focus-within {
    border-color: rgba(get-color(primary-1), 0.4);
    box-shadow: 0 0 10px rgba(get-color(primary-1), 0.2);
  }

  &__label {
    position: absolute;
    top: 0;
    left: 16px;
    padding: 0 4px;
    z-index: 1;
    transform: translateY(-50%);
    font-weight: 600;
    font-size: 16px;
    color: #4e5d78;
    line-height: 18px;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 4px;
      background-color: #fff;
    }

    > span {
      display: none;

      @include media-query(lg) {
        display: inline;
      }
    }

    &::after {
      content: attr(data-label-to-lg);

      @include media-query(lg) {
        display: none;
      }
    }
  }

  &__input {
    font-family: 'Inter', sans-serif;
    display: block;
    width: 100%;
    line-height: 58px;
    height: 58px;
    padding-left: 20px;
    padding-right: 20px;
    border: 0;
    border-radius: inherit;
    -webkit-appearance: none;
    appearance: none;
    background-position: right 21px center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1l4.25 4.25L9.5 1' stroke='%23B0B7C3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-color: get-color(bg-4);

    font-weight: 500;
    font-size: 16px;
    color: get-color(text-1);

    &--initial {
      font-weight: 500;
      font-size: 16px;
      color: get-color(text-4);
    }

    &:focus {
      outline: 0;
    }

    &:disabled {
      background-color: get-color(bg-3);
    }
  }

  &__option {
    color: get-color(text-1);

    &:disabled {
      color: get-color(text-4);
    }
  }

  &__info {
    position: absolute;
    left: 0;
    top: 100%;
    font-size: 12px;
    line-height: 16px;
    padding-top: 3px;
    color: get-color(text-3);
  }

  &--borderless {
    border: 0;
  }

  &--info-spacing {
    margin-top: 10px;
  }

  &--error {
    border-color: get-color(primary-4);

    #{$this}__info {
      color: get-color(primary-4);
    }
  }
}
</style>
